import { useEffect, useState } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
} from "react-share";

import Banner from "../banner/banner";
import Page from "../page/page";
import ReactGA from "react-ga4";

import "./BookingPage.scss";
import classNames from "classnames";

const BookingPage = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Booking Page",
    });
    //   fetch(
    //     `https://firestore.googleapis.com/v1/projects/shotam-b276d/databases/(default)/documents/bookings`,
    //   )
    //     .then((res) => {
    //       res.json().then((bookings) => {
    //         console.log(bookings);
    //       });
    //     })
    //     .catch(() => {});
    //@ts-ignore
    // window.EBWidgets.createWidget({
    //   widgetType: "checkout",
    //   eventId: "1218801972509",
    //   modal: true,
    //   modalTriggerElementId: "eventbrite-widget-modal-trigger-1218801972509",
    //   onOrderComplete: orderCallback,
    // });
    //@ts-ignore
    // window.EBWidgets.createWidget({
    //   widgetType: "checkout",
    //   eventId: "1242104009529",
    //   modal: true,
    //   modalTriggerElementId: "eventbrite-widget-modal-trigger-1242104009529",
    //   onOrderComplete: orderCallback,
    // });
  }, []);
  const [bookingType, setBookingType] = useState("");
  const [modalSoloInitialized, setModalSoloInitialized] = useState(false);
  const [modalGroupInitialized, setModalGroupInitialized] = useState(false);
  const [bookingComplete, setBookingComplete] = useState(false);

  const handleBookingTypeSelection = (type) => {
    setBookingType(type);

    if (type === "solo" && !modalSoloInitialized) {
      setModalSoloInitialized(true);
      setTimeout(() => {
        //  @ts-ignore
        window.EBWidgets.createWidget({
          widgetType: "checkout",
          eventId: "1242104009529",
          modal: true,
          modalTriggerElementId:
            "eventbrite-widget-modal-trigger-1242104009529",
          onOrderComplete: orderCallback,
        });
      }, 100);
    }
    if (type === "group" && !modalGroupInitialized) {
      setModalGroupInitialized(true);
      setTimeout(() => {
        //@ts-ignore
        window.EBWidgets.createWidget({
          widgetType: "checkout",
          eventId: "1218801972509",
          modal: true,
          modalTriggerElementId:
            "eventbrite-widget-modal-trigger-1218801972509",
          onOrderComplete: orderCallback,
        });
      }, 100);
    }
  };
  const orderCallback = function () {
    console.log("Order complete!");
    setBookingComplete(true);
  };
  return (
    <>
      <Page>
        <Banner
          texts={[`Réservation de sessions`]}
          style={{ marginTop: "100px" }}
        />

        <div className="bookingExplanation">
          Notre premier terrain de jeu est à Ivry en
          <span className="highlightText">Île de France !</span>
          <br />
          <br />
          Ca se passe dans l'espace{" "}
          <span className="highlightText">Urban Soccer Ivry</span> et il y a{" "}
          <span className="highlightText">3 étapes</span> pour réserver une
          session Shotam !
        </div>
        <div className="bookingExplanation">
          <span className="explanationNumber">1</span>
          Clique sur ton type de session.
          <button
            className={classNames(
              "showBookingButton",
              "showBookingButtonAlone",
              bookingType === "solo" ? "selected" : "",
            )}
            onClick={() => handleBookingTypeSelection("solo")}
          >
            Je suis seul
          </button>
          <div
            style={{
              fontSize: 20,
              fontWeight: 700,
              width: "100%",
              textAlign: "center",
              margin: "20px 0px",
              fontFamily: "Bungee",
            }}
          >
            OU
          </div>
          <button
            className={classNames(
              "showBookingButton",
              "showBookingButtonGroup",
              bookingType === "group" ? "selected" : "",
            )}
            onClick={() => handleBookingTypeSelection("group")}
          >
            Je réserve pour un groupe de 4 minimum
          </button>
        </div>
        {/* <div className="bookingExplanation">
          <span className="explanationNumber">1</span>
          Choisis ta session et réserve en 3 clics.
          <div className="explanationHint">
            Il faut minimum 4 joueurs par réservation !
          </div>
          <button
            id="eventbrite-widget-modal-trigger-1218801972509"
            type="button"
            className="showBookingButton"
          >
            Commencer
          </button>
        </div> */}
        <div className="bookingExplanation">
          <span className="explanationNumber">2</span>
          {bookingType.length === 0 ? (
            <div className="explanationHint">
              En attente de validation de l'étape 1 ....
            </div>
          ) : (
            <>Réserve ta session !</>
          )}
          {bookingType === "solo" && (
            <button
              id="eventbrite-widget-modal-trigger-1242104009529"
              type="button"
              className="showBookingButton"
            >
              Acheter un pass Solo
            </button>
          )}
          {bookingType === "group" && (
            <button
              id="eventbrite-widget-modal-trigger-1218801972509"
              type="button"
              className="showBookingButton"
            >
              Réserver un terrain pour mon groupe
            </button>
          )}
        </div>

        {/* <div className="bookingExplanation">
          <span className="explanationNumber">2</span>
          <div className="explanationHint">
            Il peut y avoir jusqu'à 12 joueurs par partie !
          </div>
          Tu peux inviter tes amis en leur partageant cette page !
          <div className="shareContainer">
            <FacebookShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <FacebookIcon />
            </FacebookShareButton>
            <TwitterShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <TwitterIcon />
            </TwitterShareButton>
            <LinkedinShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <LinkedinIcon />
            </LinkedinShareButton>
            <WhatsappShareButton
              url="https://shotamsekai.com"
              className="shareButton"
            >
              <WhatsappIcon />
            </WhatsappShareButton>
          </div>
          <br />
        </div> */}
        <div className="bookingExplanation">
          <span className="explanationNumber">3</span>
          C'est tout bon, il ne te reste plus qu'à venir le jour J !
          <br />
          <br /> Entre temps, on reste joignables à l'adresse suivante :
          <span className="highlightText">contact@shotam.app</span>
          <br />
          <br />A bientôt sur le terrain de jeu !
          <div className="explanationHint">
            Attention à bien être à l'heure, le terrain est réservé sur un
            créneau fixe.
          </div>
        </div>
      </Page>
    </>
  );
};

export default BookingPage;
